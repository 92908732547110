import { useState, useEffect } from "react";
import { Stack, IconButton, Text } from "@fluentui/react";
import styles from "./Answer.module.css";
import { AskResponse, Log } from "../../api";
import { SessionAnswer } from "../SessionCards/Session";
import PopUp from "../PopUp/PopUp";
import { marked } from "marked";
import {
  conversationLog,
  fetchWordDocument,
  exceptionLog,
} from "../../api/api";
import { ResponseDisclamier, AnswerStatus } from "../../api/constants";
import configureMarkdownRenderer from "../Helper/MarkdownRenderer";

/**
 * Props for the Answer component.
 */
interface Props {
  answer: AskResponse;
  sessionID: string;
  conversationID: string;
  messageID: string;
  messageIndex: number;
  trackFeedback: string[];
  statusBackpack: boolean[][];
  statusSession: boolean[][];
  isAnswerGenerated: string;
}

/**
 * Renders an answer component with the provided props.
 * @param answer - The AskResponse object containing the answer data.
 * @param sessionID - The ID of the current session.
 * @param conversationID - The ID of the current conversation.
 * @param messageID - The ID of the current message.
 * @param messageIndex - The index of the current message.
 * @returns A React component representing the answer.
 */

export const Answer = ({
  answer,
  sessionID,
  conversationID,
  messageID,
  messageIndex,
  trackFeedback,
  statusBackpack,
  statusSession,
  isAnswerGenerated,
}: Props) => {
  configureMarkdownRenderer();
  const [answerStatus, setAnswerStatus] = useState<AnswerStatus>(
    AnswerStatus.NEUTRAL
  );
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  useEffect(() => {
    const status = trackFeedback[messageIndex];
    if (
      status === AnswerStatus.LIKED ||
      status === AnswerStatus.DISLIKED ||
      status === AnswerStatus.NEUTRAL
    )
      setAnswerStatus(status);
  }, []);
  const likeButtonProperties = {
    bold: answerStatus === AnswerStatus.LIKED,
    color: answerStatus === AnswerStatus.LIKED ? "green" : "black",
    iconName: answerStatus === AnswerStatus.LIKED ? "LikeSolid" : "Like",
  };

  const dislikeButtonProperties = {
    bold: answerStatus === AnswerStatus.DISLIKED,
    color: answerStatus === AnswerStatus.DISLIKED ? "red" : "black",
    iconName:
      answerStatus === AnswerStatus.DISLIKED ? "DislikeSolid" : "Dislike",
  };

  const handleSubmitPopup = (content: string) => {
    const log: Log = {
      sessionID: sessionID,
      conversationID: conversationID,
      messageID: messageID,
      logType: "Feedback",
      action: "Disliked",
      feedback: content,
    };

    conversationLog(log);
    setIsDialogVisible(false);
  };

  const handleLikeDislikeClick = (action: string) => {
    let newStatus;
    let logAction;
    let isWithoutFeedBack = false;
    if (action === "Liked" && answerStatus !== AnswerStatus.LIKED) {
      newStatus = AnswerStatus.LIKED;
      logAction = "Liked";
      isWithoutFeedBack = true;
    } else if (action === "Liked" && answerStatus === AnswerStatus.LIKED) {
      newStatus = AnswerStatus.NEUTRAL;
      logAction = "Neutral";
      isWithoutFeedBack = true;
    } else if (
      action === "Disliked" &&
      answerStatus !== AnswerStatus.DISLIKED
    ) {
      newStatus = AnswerStatus.DISLIKED;
      logAction = "Disliked";
      setIsDialogVisible(true);
    } else if (
      action === "Disliked" &&
      answerStatus === AnswerStatus.DISLIKED
    ) {
      newStatus = AnswerStatus.NEUTRAL;
      logAction = "Neutral";
      setIsDialogVisible(false);
      isWithoutFeedBack = true;
    } else {
      newStatus = AnswerStatus.NEUTRAL;
      logAction = "Neutral";
      setIsDialogVisible(false);
    }

    if (isWithoutFeedBack) {
      const log: Log = {
        sessionID: sessionID,
        conversationID: conversationID,
        messageID: messageID,
        logType: "Feedback",
        action: logAction,
        feedback: "",
      };

      conversationLog(log);
    }
    setAnswerStatus(newStatus); //local storage
    trackFeedback[messageIndex] = newStatus;
    localStorage.setItem("feedback", JSON.stringify(trackFeedback));
    localStorage.setItem(
      "refresh",
      String(window.crypto.getRandomValues(new Uint32Array(1))[0])
    );
  };

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  const markdownAnswer = marked(answer.answer);

  const updateMarkdown = (mdtxt: string) => {
    var mdtxt1 = mdtxt;
    if (answer.answer.includes("\n\n")) {
      const pTags = (mdtxt.match(/<p/g) || []).length;
      if (pTags > 1) {
        const regex = /(<p[^>]*>)(?![\s\S]*<p[^>]*>)/;
        mdtxt1 = mdtxt.replace(
          regex,
          `<span style="display:block; height: 0.5em;"></span>$1`
        );
      }
    }
    return mdtxt1;
  };

  useEffect(() => {
    let downloaded = localStorage.getItem("downloaded");

    if (
      downloaded !== "true" &&
      answer.intent.trim().toLowerCase().includes("downloadbackpack") &&
      !answer.attendeeType.trim().toLowerCase().includes("anonymous")
    ) {
      const message = {
        type: "command",
        data: [
          {
            object: "backpack",
            action: "downloadBackpackWithSchedule",
          },
        ],
      };
      window.parent.postMessage(message, "*");
      localStorage.setItem("downloaded", "true");
    }
  }, [answer]);

  useEffect(() => {
    let downloaded = localStorage.getItem("downloaded");
    let downloadTripReport = localStorage.getItem("downloadTripReport");
    if (
      downloaded !== "true" &&
      answer.intent.trim().toLowerCase().includes("tripreport") &&
      !answer.attendeeType.trim().toLowerCase().includes("anonymous") &&
      (answer.sessionMetadata.length > 0 || answer.partnerMetadata.length > 0)
    ) {
      if (downloadTripReport === isAnswerGenerated) {
        fetchWordDocument(answer.partnerMetadata, answer.sessionMetadata)
          .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);

            // Create an anchor element
            const link = document.createElement("a");
            link.id = messageID;
            link.href = blobUrl;
            link.download = "Microsoft Ignite Trip Report.docx";

            // Simulate a click on the anchor element to trigger the download
            link.click();
            link.remove();
            // Clean up: revoke the blob URL
            URL.revokeObjectURL(blobUrl);
            localStorage.setItem("downloaded", "true");
          })
          .catch((error) => {
            exceptionLog(
              error,
              sessionID,
              conversationID,
              messageID,
              "DownloadTripReport"
            );
            console.error("Error:", error.message);
          });
      }
    }
  }, [answer]);

  return (
    <Stack
      className={`${styles.answerContainer}`}
      verticalAlign="space-between"
    >
      <span
        className={styles.visually_hidden}
        aria-live="polite"
        aria-atomic="true"
      >
        {isComponentMounted ? answer.answer : "Answer Response not come"}
      </span>
      <Stack.Item grow>
        {answer.metadata &&
        answer.metadata.length > 0 &&
        answer.intent &&
        answer.attendeeType ? (
          <div>
            {answer.header && (
              <div
                className={styles.header}
                dangerouslySetInnerHTML={{ __html: marked(answer.header) }}
              ></div>
            )}

            <SessionAnswer
              metadata={answer.metadata}
              attendeeType={answer.attendeeType}
              intent={answer.intent}
              messageIndex={messageIndex}
              statusBackpack={statusBackpack}
              statusSession={statusSession}
            />

            {answer.footer && (
              <div
                className={styles.footer}
                dangerouslySetInnerHTML={{ __html: marked(answer.footer) }}
              ></div>
            )}
          </div>
        ) : ["partner", "speaker", "facilit"].some((item) =>
            answer.intent.trim().toLowerCase().includes(item)
          ) ? (
          <div
            className={styles.answerText}
            dangerouslySetInnerHTML={{ __html: updateMarkdown(markdownAnswer) }}
          ></div>
        ) : (
          <div
            className={styles.answerText}
            dangerouslySetInnerHTML={{ __html: markdownAnswer }}
          ></div>
        )}
      </Stack.Item>

      {isDialogVisible && (
        <PopUp
          isOpen={isDialogVisible}
          onDismiss={() => setIsDialogVisible(false)}
          onSubmit={handleSubmitPopup}
        />
      )}
      {messageIndex !== 0 && (
        <Stack.Item>
          <Stack horizontal horizontalAlign="space-between">
            <Text variant="small" styles={{ root: { paddingTop: "10px" } }}>
              {ResponseDisclamier.disclaimer}
            </Text>
            <div>
              <span
                className={styles.visually_hidden}
                aria-live="polite"
                aria-atomic="true"
              >
                {answerStatus === AnswerStatus.LIKED
                  ? "Message liked"
                  : "Like removed"}
              </span>
              <IconButton
                className={
                  likeButtonProperties.bold ? "styles.bold-button" : ""
                }
                style={{ color: likeButtonProperties.color }}
                iconProps={{ iconName: likeButtonProperties.iconName }}
                title="Like"
                onClick={() => handleLikeDislikeClick("Liked")}
              />
              <span
                className={styles.visually_hidden}
                aria-live="polite"
                aria-atomic="true"
              >
                {answerStatus === AnswerStatus.DISLIKED
                  ? "Message disliked"
                  : "Dislike removed"}
              </span>
              <IconButton
                className={
                  dislikeButtonProperties.bold ? "styles.bold-button" : ""
                }
                style={{ color: dislikeButtonProperties.color }}
                iconProps={{ iconName: dislikeButtonProperties.iconName }}
                title="Dislike"
                onClick={() => handleLikeDislikeClick("Disliked")}
              />
            </div>
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};
