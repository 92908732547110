import { useRef, useState, useEffect, useReducer, RefObject } from "react";
import styles from "./Chat.module.css";
import stylesInput from "../../components/QuestionInput/QuestionInput.module.css";
import { AskResponse, chatApi, ChatRequest, ChatTurn, Log } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { DefaultAnswer } from "../../components/Answer/DefaultAnswer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { GenerateUUID } from "./Key";
import { conversationLog, searchQuery, exceptionLog } from "../../api/api";
import { WelcomeMessage } from "../../api/constants";
import { fetchPromptGuide } from "../../api/api";
import { fetchKillSwitch } from "../../api/api";
import { use } from "marked";

interface Props {
  sessionID: string;
  conversationID: string;
  loadMessageID: string;
  attendeeType: string;
  attendeeTypeWelcomeMessage: any;
  scroller: any;
}

/**
 * A chat component that allows users to interact with a chatbot.
 *
 * @param sessionID - The ID of the current session.
 * @param conversationID - The ID of the current conversation.
 * @param loadMessageID - The ID of the message to load.
 * @param followUpDefaultQuestions - An array of default follow-up questions to suggest to the user.
 */
const Chat = ({
  sessionID,
  conversationID,
  loadMessageID,
  attendeeType,
  attendeeTypeWelcomeMessage,
  scroller,
}: Props) => {
  let welcomeMessage = "";
  try {
    if (attendeeTypeWelcomeMessage) {
      if (attendeeType.toLowerCase().includes("digital")) {
        welcomeMessage = attendeeTypeWelcomeMessage.digital;
      } else if (attendeeType.toLowerCase().includes("in-person")) {
        welcomeMessage = attendeeTypeWelcomeMessage.inperson;
      } else if (attendeeType.toLowerCase().includes("anonymous")) {
        welcomeMessage = attendeeTypeWelcomeMessage.anonymous;
      }
    } else {
      welcomeMessage = WelcomeMessage.message;
    }
  } catch (error: any) {
    welcomeMessage = WelcomeMessage.message;
    exceptionLog(
      error,
      sessionID,
      conversationID,
      "DefaultWelcomeMessage",
      "getWelcomeMessageCDN"
    );
  }

  const message: AskResponse = {
    answer: welcomeMessage,
    thoughts: "",
    intent: "",
    metadata: [],
    attendeeType: "",
    partnerMetadata: [],
    sessionMetadata: [],
  };

  const [clearConversationID, setclearConversationID] = useState<string>("");
  const [messageID, setMessageID] = useState(GenerateUUID);
  const [keyValueArray, setKeyValueArray] = useState<
    { key: number; value: string }[]
  >([{ key: 0, value: GenerateUUID() }]);
  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isQuestionComplete, setQuestionComplete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scrollPage, setScrollPage] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [answers, setAnswers] = useState<[string, AskResponse][]>([
    ["", message],
  ]);
  const [statusBackpack, setStatusBackpack] = useState<boolean[][]>([
    [false],
    [false],
  ]);
  const [statusSession, setStatusSession] = useState<boolean[][]>([
    [false],
    [false],
  ]);
  const [id, setID] = useState<string[]>([]);
  const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
  const [showFollowUpButtons, setShowFollowUpButtons] = useState(false);
  const [trackFeedback, setTrackFeedback] = useState<string[]>(["NEUTRAL"]);
  const [index, setIndex] = useState<number>(0);
  const [hideFollowUpButtons, setHideFollowUpButtons] = useState(false);
  const [intentQuestions, setIntentQuestions] = useState([]);
  const [isIntent, setIsIntent] = useState("");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [killSwitch, setKillSwitch] = useState<boolean>(false);
  const [consecutiveDefaultAnswers, setConsecutiveDefaultAnswers] =
    useState<number>(0);
  const [isHover, setisHover] = useState(false);
  const [isAnswerGenerated, setIsAnswerGenerated] = useState("");

  const scrolled = (chat: RefObject<HTMLDivElement>) => {
    if (isHover) return;
    if (!chat.current) return;
    setTimeout(() => {
      chat.current?.scrollIntoView();
      setisHover(true);
    }, 300);
  };
  useEffect(() => {
    // Initialize chat history from sessionStorage
    const sessionAns = localStorage.getItem("answer");
    const sessionParsedResponseAns = localStorage.getItem("parsedResponse");
    const sessionQuestion = localStorage.getItem("question");
    const sessionMessage = localStorage.getItem("messageHistory");
    const sessionNewKeyValuePair = localStorage.getItem("newKeyValuePair");
    const localFeedback = localStorage.getItem("feedback");
    const localIndex = localStorage.getItem("index");
    const localBackpack = localStorage.getItem("setBack");
    const localSession = localStorage.getItem("setSession");

    if (
      sessionAns !== null &&
      localBackpack !== null &&
      localSession !== null &&
      sessionParsedResponseAns !== null &&
      sessionQuestion !== null &&
      sessionMessage !== null &&
      sessionNewKeyValuePair !== null
    ) {
      JSON.parse(sessionAns);
      const newResponse: [string, AskResponse] = [
        sessionQuestion,
        JSON.parse(sessionParsedResponseAns),
      ];
      setAnswers([...JSON.parse(sessionAns), newResponse]);
      setKeyValueArray([
        ...JSON.parse(sessionMessage),
        JSON.parse(sessionNewKeyValuePair),
      ]);
      lastQuestionRef.current = sessionQuestion;
      setStatusSession([...JSON.parse(localSession), [false]]);
      setStatusBackpack([...JSON.parse(localBackpack), [false]]);
    }
    if (localFeedback !== null) {
      setTrackFeedback([...JSON.parse(localFeedback)]);
    }
    if (localIndex !== null) {
      setIndex(parseInt(localIndex));
    }
    const intervalId = setInterval(() => {
      if (chatMessageStreamEnd.current !== null) {
        scroller(chatMessageStreamEnd);
        clearInterval(intervalId); // Clear interval if chatMessageStreamEnd is not null
      }
    }, 10); // Check every 10 miliseconds

    // Clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [ignored]);

  const updateValueAtIndex = (index: number, newValue: string) => {
    const newArray = [...trackFeedback]; // Create a copy of the array
    newArray[index] = newValue; // Assign the new value at the specified index in the copy
    setTrackFeedback(newArray); // Update the state variable with the modified copy
  };

  window.addEventListener("storage", function (event) {
    if (event.key === "refresh" || event.key === "feedback") {
      forceUpdate();
    }
  });

  const makeApiRequest = async (question: string, followUpFlag: string) => {
    lastQuestionRef.current = question;
    setMessageID(GenerateUUID());
    error && setError(undefined);
    setIsLoading(true);
    setScrollPage(true);
    let indexMessage = index + 1;
    updateValueAtIndex(indexMessage, "NEUTRAL");
    localStorage.setItem("index", indexMessage.toString());
    setIndex(indexMessage);
    const newKeyValuePair = { key: index, value: messageID };
    setStatusBackpack((prevState) => [...prevState, [false, false]]);
    setStatusSession((prevState) => [...prevState, [false, false]]);
    localStorage.setItem("setBack", JSON.stringify(statusBackpack));
    localStorage.setItem("setSession", JSON.stringify(statusSession));
    setKeyValueArray((prevState) => [...prevState, newKeyValuePair]);
    localStorage.setItem("messageHistory", JSON.stringify(keyValueArray));
    localStorage.setItem("newKeyValuePair", JSON.stringify(newKeyValuePair));
    try {
      const history: ChatTurn[] = answers
        .filter(
          (a) =>
            !a[1].answer.startsWith(
              "I am super busy now helping others, please try again in few minutes."
            )
        )
        .map((a) => ({
          user: a[0],
          bot: a[1].answer,
          intent: a[1].intent,
          context: a[1].context,
          modifiedQuestion: a[1].modifiedQuestion,
        }));
      const request: ChatRequest = {
        history: [...history.slice(-2)],
        question: question,
        followUpFlag: followUpFlag,
        visited: [...id],
        sessionID: sessionID,
        conver:
          clearConversationID === "" ? conversationID : clearConversationID,
        messageID: messageID,
        logType: "Response",
      };

      let retries = 2; // Number of retries
      while (retries > 0) {
        const response = await chatApi(request);
        if (response.status === 200) {
          const parsedResponse: AskResponse = await response.json();
          // Check if the response starts with "Unfortunately" or if it's a default answer
          if (
            parsedResponse.answer.startsWith("Unfortunately") ||
            parsedResponse.answer === DefaultAnswer({ attendeeType }).answer
          ) {
            // If it's a default answer, increment the count of consecutive default answers
            setConsecutiveDefaultAnswers((prevCount) => prevCount + 1);
          } else {
            // If it's not a default answer, reset the count of consecutive default answers
            setConsecutiveDefaultAnswers(0);
          }
          // If there have been 20 consecutive default answers
          if (consecutiveDefaultAnswers >= 20) {
            // Disable the chat input
            setIsComplete(true);

            // Create a special message to ask the user to clear the chat history and restart the chat
            const specialMessage: AskResponse = {
              answer:
                "We're having trouble understanding your questions. Please clear the chat history and restart the chat.",
              header: "",
              footer: "",
              thoughts: "",
              attendeeType: "",
              context: "",
              intent: "",
              metadata: [],
              sessionMetadata: [],
              partnerMetadata: [],
              userTimezone: "",
            };

            // Add the special message to the answers
            const newResponse: [string, AskResponse] = [
              question,
              specialMessage,
            ];
            setAnswers([...answers, newResponse]);
            localStorage.setItem("answer", JSON.stringify(answers));
            localStorage.setItem(
              "parsedResponse",
              JSON.stringify(specialMessage)
            );
            localStorage.setItem("question", question);
            if (localStorage.getItem("downloaded") !== null) {
              localStorage.removeItem("downloaded");
            }
            // Stop processing the current request
            return;
          }
          const newResponse: [string, AskResponse] = [question, parsedResponse];
          setAnswers([...answers, newResponse]);
          localStorage.setItem("answer", JSON.stringify(answers));
          localStorage.setItem(
            "parsedResponse",
            JSON.stringify(parsedResponse)
          );
          localStorage.setItem("question", question);
          if (localStorage.getItem("downloaded") !== null) {
            localStorage.removeItem("downloaded");
          }
          let downloadTripReportValue = String(
            window.crypto.getRandomValues(new Uint32Array(1))[0] * 1000
          );
          setIsAnswerGenerated(downloadTripReportValue);
          localStorage.setItem("downloadTripReport", downloadTripReportValue);
          setIsIntent(parsedResponse.intent);
          break;
        }
        retries--;
        if (retries === 0) {
          throw new Error("Retries exceeded");
        }
      }
    } catch (e: any) {
      exceptionLog(e, sessionID, conversationID, messageID, "makeApiRequest");
      const parsedResponse: AskResponse = DefaultAnswer({ attendeeType });
      const newResponse: [string, AskResponse] = [question, parsedResponse];
      setAnswers([...answers, newResponse]);
      localStorage.setItem("answer", JSON.stringify(answers));
      localStorage.setItem("parsedResponse", JSON.stringify(parsedResponse));
      localStorage.setItem("question", question);
      if (localStorage.getItem("downloaded") !== null) {
        localStorage.removeItem("downloaded");
      }
    } finally {
      localStorage.setItem(
        "refresh",
        String(window.crypto.getRandomValues(new Uint32Array(1))[0] * 1000)
      );
      setIsLoading(false);
    }
  };

  const fetchPromptGuideTable = async () => {
    try {
      const tableData = await fetchPromptGuide();

      tableData.forEach(
        (item: {
          intent: any;
          question: any;
          orderby: any;
          StartTime: any;
          EndTime: any;
        }) => {
          delete item.StartTime;
          delete item.EndTime;
        }
      );
      if (tableData) {
        setIntentQuestions(tableData);
      }
    } catch (error: any) {
      exceptionLog(
        error,
        sessionID,
        conversationID,
        messageID,
        "fetchPromptGuideTable"
      );
    }
  };

  const fetchKillSwitchStatus = async () => {
    let status = "0";
    try {
      const switchstatus = await fetchKillSwitch();
      if (switchstatus && switchstatus.length > 0) {
        if (
          switchstatus[0] === "1" ||
          switchstatus[0] === "2" ||
          switchstatus[0] === "3"
        ) {
          status = switchstatus[0];
          clearChat();
          message.answer = switchstatus[1];
          setAnswers([["", message]]);
          setKillSwitch(true);
        }
      }
    } catch (error: any) {
      exceptionLog(
        error,
        sessionID,
        conversationID,
        messageID,
        "fetchKillSwitchStatus"
      );
    }
    return status;
  };

  const clearChat = () => {
    lastQuestionRef.current = "";
    error && setError(undefined);
    setAnswers([["", message]]);
    setStatusBackpack([[false], [false]]);
    setStatusSession([[false], [false]]);
    localStorage.removeItem("answer");
    localStorage.removeItem("parsedResponse");
    localStorage.removeItem("question");
    localStorage.removeItem("feedback");
    localStorage.removeItem("index");
    localStorage.removeItem("newConversationID");
    localStorage.removeItem("setBack");
    localStorage.removeItem("setSession");
    localStorage.removeItem("newSessionID");
    localStorage.removeItem("downloaded");
    localStorage.setItem(
      "refresh",
      String(window.crypto.getRandomValues(new Uint32Array(1))[0] * 1000)
    );
    setTrackFeedback([]);
    setKeyValueArray([{ key: 0, value: GenerateUUID() }]);
    setID([]);
    setIndex(0);
    const newGUUID = GenerateUUID();
    setclearConversationID(newGUUID);
    localStorage.setItem("newConversationID", newGUUID);
    const log: Log = {
      sessionID: sessionID,
      conversationID: newGUUID,
      messageID: "",
      logType: "Clear Chat",
      action: "Neutral",
      feedback: "",
    };
    conversationLog(log);
    fetchPromptGuideTable();
  };

  useEffect(() => {
    fetchKillSwitchStatus();
    fetchPromptGuideTable();
  }, []);

  useEffect(() => {
    const fetchsearchQuery = async () => {
      if (
        index === 0 &&
        searchQuery &&
        sessionID !== "" &&
        keyValueArray.length > index
      ) {
        let status = "0";
        fetchKillSwitchStatus().then((killSwitch) => {
          status = killSwitch;
          if (status === "0" && searchQuery !== null) {
            makeApiRequest(searchQuery, "0");
          }
        });
      } else if (
        searchQuery &&
        keyValueArray.length > index &&
        sessionID === localStorage.getItem("newSessionID")
      ) {
        let status = "0";
        fetchKillSwitchStatus().then((killSwitch) => {
          status = killSwitch;
          if (status === "0" && searchQuery !== null) {
            makeApiRequest(searchQuery, "0");
          }
        });
      }
    };
    fetchsearchQuery();
  }, [sessionID]);

  useEffect(
    () =>
      chatMessageStreamEnd.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
        behavior: "smooth",
      }),
    [isLoading, scrollPage]
  );

  useEffect(() => setScrollPage(false), [scrollPage]);

  return (
    <>
      <div className={styles.commandsContainer}></div>
      <div
        className={styles.wrapper}
        onMouseEnter={() => {
          scrolled(chatMessageStreamEnd);
        }}
      >
        <div className={styles.chatMaster}>
          <div className={styles.container}>
            <div className={styles.chatRoot}>
              <div className={styles.chatContainer}>
                <div className={styles.chatContainerParent}>
                  <div className={styles.parentChatModule}>
                    <div className={styles.chatMessageStream}>
                      {answers.map((answer, index) => (
                        <div key={index}>
                          {answer[0] !== "" ? (
                            <UserChatMessage message={answer[0]} />
                          ) : (
                            <></>
                          )}
                          <div className={styles.chatMessageGpt}>
                            <Answer
                              key={index}
                              answer={answer[1]}
                              sessionID={sessionID}
                              conversationID={
                                clearConversationID === ""
                                  ? conversationID
                                  : clearConversationID
                              }
                              messageID={
                                keyValueArray.length > index
                                  ? keyValueArray[index].value
                                  : ""
                              }
                              messageIndex={index}
                              trackFeedback={trackFeedback}
                              statusBackpack={statusBackpack}
                              statusSession={statusSession}
                              isAnswerGenerated={isAnswerGenerated}
                            />
                          </div>
                        </div>
                      ))}
                      {isLoading && (
                        <>
                          <UserChatMessage message={lastQuestionRef.current} />
                          <div className={styles.chatMessageGptMinWidth}>
                            {isComplete ? null : (
                              <AnswerLoading
                                lastQuestionRef={lastQuestionRef.current}
                              />
                            )}
                          </div>
                        </>
                      )}
                      {error ? (
                        <>
                          <UserChatMessage message={lastQuestionRef.current} />
                          <div className={styles.chatMessageGptMinWidth}>
                            <AnswerError error={error.toString()} />
                          </div>
                        </>
                      ) : null}
                      <div ref={chatMessageStreamEnd}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!killSwitch ? (
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <div
            className={styles.chatInput}
            onMouseEnter={() => {
              scrolled(chatMessageStreamEnd);
            }}
          >
            <QuestionInput
              clearOnSend
              className={
                isComplete || isQuestionComplete
                  ? stylesInput.questionInputContainergrey
                  : stylesInput.questionInputContainer
              }
              placeholder="Ask me anything about Microsoft Ignite..."
              disabled={isLoading || isComplete || isQuestionComplete}
              onSend={(question) => makeApiRequest(question, "0")}
              inputDisables={isComplete || isQuestionComplete}
              lastQuestionRef={lastQuestionRef}
              isLoading={isLoading}
              clearChat={clearChat}
              makeApiRequest={makeApiRequest}
              intentQuestions={intentQuestions}
              intent={isIntent}
              scroller={scroller}
              chatMessageStreamEnd={chatMessageStreamEnd}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Chat;
